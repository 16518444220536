import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { useUpdatePostMutation } from '@/RTK/services/postsApi';
import {
  useDeleteFileMutation,
  useGetPresignedUrlMutation,
} from '@/RTK/services/s3Api';
// Import the RTK mutation
import { useGetUserQuery } from '@/RTK/services/userApi';
import { DialogTitle } from '@radix-ui/react-dialog';
import {
  Bold,
  Image as ImageIcon,
  Link,
  Video as VideoIcon,
  X,
} from 'lucide-react';
import { AiOutlineEdit } from 'react-icons/ai';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Textarea } from '@/components/ui/textarea';

import Loader from '../../../../../utils/Loader';
import { UpdateMediaCarousel } from './updateMediaCarousel';

export function EditPostDialog({
  tweet,
  isEditPostDialogOpen,
  setIsEditPostDialogOpen,
}) {
  const [content, setContent] = useState(tweet?.content?.text || '');
  const [images, setImages] = useState([]); // Store uploaded image URLs
  const [video, setVideo] = useState(null); // Store uploaded video URL
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);

  // temporary storage for images and videos to store image and video that user deSelects while editing post and this state will be used to
  // delete the image and video from s3 when user clicks on update button
  const [temporaryImages, setTemporaryImages] = useState([]);
  const [temporaryVideo, setTemporaryVideo] = useState([]);

  const textareaRef = useRef(null);

  // RTK Mutations
  const [updatePost, { isLoading: isUpdating }] = useUpdatePostMutation();
  const [getPresignedUrl] = useGetPresignedUrlMutation();
  const [deleteFile] = useDeleteFileMutation();
  const { data: user, isLoading: userLoading } = useGetUserQuery(undefined);

  // Function to get a pre-signed URL and upload a file to S3
  const uploadFileToS3 = async (file) => {
    try {
      // Step 1: Get a pre-signed URL
      const { url, fileUrl } = await getPresignedUrl(file.type).unwrap();

      // Step 2: Upload the file to S3
      await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });

      // Step 3: Return the uploaded file URL
      return fileUrl;
    } catch (error) {
      console.error('Error uploading file to S3:', error);
      return null;
    }
  };

  // Function to handle image selection and upload
  const handleImageUpload = async (event) => {
    const files = event.target.files;
    if (files.length) {
      setIsUploadingMedia(true);
      const uploadedUrls = await Promise.all(
        Array.from(files).map((file) => uploadFileToS3(file))
      );

      let tempArray = uploadedUrls.filter(Boolean);
      tempArray = tempArray.map((image) => {
        return { image, existing: false };
      });
      setImages((prevImages) => [...tempArray, ...prevImages]);
      setIsUploadingMedia(false);
    }
    event.target.value = null;
  };

  // function to remover video from s3
  const removeVideoFromS3 = async (videoUrl) => {
    try {
      const key = videoUrl.split('.com/')[1]; // Extract S3 object key
      deleteFile(key).unwrap();
    } catch (error) {
      console.error('Error deleting video file:', error);
    }
  };

  // Function to handle video selection and upload
  const handleVideoUpload = async (event) => {
    if (video?.video) {
      setTemporaryVideo((prevVideo) => [...prevVideo, video.video]);
    }

    const file = event.target.files[0];
    if (file) {
      setIsUploadingMedia(true);
      const uploadedUrl = await uploadFileToS3(file);
      setVideo({ video: uploadedUrl, existing: false });
    }
    event.target.value = null;
    setIsUploadingMedia(false);
  };

  const removeImageFromS3 = async (imageUrl) => {
    try {
      const key = imageUrl.split('.com/')[1]; // Extract S3 object key
      deleteFile(key).unwrap();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  // Function to remove an image from the list
  const removeImage = async (imageUrl) => {
    setTemporaryImages((prevImages) => [...prevImages, imageUrl]);

    // Remove the image from the list
    setImages((prevImages) =>
      prevImages.filter((img) => img.image !== imageUrl)
    );
  };

  const removeVideo = async (videoUrl) => {
    setTemporaryVideo((prevVideo) => [...prevVideo, videoUrl]);
    setVideo(null);
  };

  useEffect(
    function closeDialogOnPostCreation() {
      if (!isUpdating) {
        setIsEditPostDialogOpen(false);
      }
    },
    [isUpdating]
  );

  useEffect(() => {
    if (isEditPostDialogOpen) {
      setContent(tweet?.content?.text || '');
      let tempArray = tweet?.content?.images?.map((image) => {
        return { image, existing: true };
      });
      if (tweet?.content?.video) {
        setVideo({ video: tweet?.content?.video, existing: true });
      }
      setImages(tempArray);
    }
  }, [isEditPostDialogOpen, tweet]);
  // Function to create a post
  const handleCreatePost = async (e) => {
    e.stopPropagation();
    try {
      const response = await updatePost({
        postId: tweet._id,
        updatedData: {
          content: {
            text: content,
            images: images.map((image) => image.image),
            video: video?.video || null,
          },
        },
      }).unwrap();

      setContent('');
      setImages([]);
      setVideo(null);
      temporaryImages.forEach((imageUrl) => removeImageFromS3(imageUrl));
      temporaryVideo.forEach((videoUrl) => removeVideoFromS3(videoUrl));

      setTemporaryImages([]);
      setTemporaryVideo([]);
      textareaRef.current.focus();
    } catch (error) {
      console.error('Error creating post:', error);
    } finally {
      setIsEditPostDialogOpen(false);
    }
  };

  // Function to close the dialog it deletes any video or images that are uploaded to aws on closing the dialog
  async function handleCloseDialog(e) {
    e.stopPropagation();
    setContent('');

    images.forEach(async (image) => {
      if (!image.existing) {
        removeImageFromS3(image.image);
      }
    });
    if (video && !video.existing) removeVideoFromS3(video.video);
    setIsEditPostDialogOpen(false);
  }

  return (
    <AlertDialog
      open={isEditPostDialogOpen}
      onOpenChange={setIsEditPostDialogOpen}
    >
      <AlertDialogTrigger asChild className="hidden">
        <button className="flex w-full shrink-0 justify-between rounded-[6px] text-[#505050]">
          تحرير المشاركة <AiOutlineEdit size={16} color="#636363" />
        </button>
      </AlertDialogTrigger>
      <AlertDialogContent
        className="sm:max-w-[600px] p-6 rounded-2xl w-[95vw] min-h-fit"
        dir="rtl"
        onInteractOutside={(e) => e.preventDefault()}
        onClick={(e) => e.stopPropagation()}
      >
        <AlertDialogTitle className="hidden">
          Upload Media AlertDialog
        </AlertDialogTitle>
        <AlertDialogHeader className="flex flex-col p-2">
          <div className="flex items-center gap-3">
            {user?.user?.profileImage ? (
              <Image
                width={53}
                height={53}
                src={user.user.profileImage}
                alt="User"
                className="size-[53px] rounded-full"
              />
            ) : (
              <div className="size-[53px] rounded-full bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center">
                <span className="text-sm font-medium text-purple-600">
                  {user?.user?.name?.charAt(0) || 'A'}
                </span>
              </div>
            )}
            <div className="flex flex-col gap-2 justify-end">
              <h3 className="text-[18px] font-semibold text-right text-[#65656B]">
                {user?.user?.name || 'username'}
              </h3>
            </div>
          </div>
        </AlertDialogHeader>
        <ScrollArea
          className={`${images.length || video ? 'h-[330px]' : 'h-[150px]'}`}
        >
          <textarea
            ref={textareaRef}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            dir="rtl"
            placeholder="ماذا يخطر ببالك؟"
            className="w-full h-[150px] p-4 text-right text-gray-900 !border-none focus:ring-0 ring-0 !outline-non focus:outline-none resize-none"
          />

          {(images.length || video) && (
            <UpdateMediaCarousel
              key={tweet._id}
              image={images}
              removeImage={removeImage}
              video={video}
              removeVideo={removeVideo}
            />
          )}
        </ScrollArea>

        <div className="flex justify-between items-center gap-3 mt-4 flex-wrap">
          <div className="flex items-center gap-4 mt-2 text-gray-500">
            {/* <Bold className="w-5 h-5 cursor-pointer hover:text-gray-700" /> */}
            {/* <Link className="w-5 h-5 cursor-pointer hover:text-gray-700" /> */}
            <label className="cursor-pointer">
              <ImageIcon className="w-5 h-5 hover:text-gray-700" />
              <input
                type="file"
                accept="image/*"
                multiple
                className="hidden"
                disabled={isUploadingMedia}
                onChange={handleImageUpload}
              />
            </label>
            <label className="cursor-pointer">
              <VideoIcon className="w-5 h-5 hover:text-gray-700" />
              <input
                disabled={isUploadingMedia}
                type="file"
                accept="video/*"
                className="hidden"
                onChange={handleVideoUpload}
              />
            </label>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={handleCloseDialog}
              className="bg-gray-300 text-gray-700 px-6 py-2 rounded-lg w-[75px] h-[31px]"
            >
              إلغاء
            </Button>
            <Button
              onClick={handleCreatePost}
              disabled={
                isUploadingMedia ||
                (images.length === 0 && !video && content.trim() === '')
              }
              className={`bg-purple-600 text-white px-6 py-2 rounded-lg w-[75px] h-[31px] ${isUploadingMedia ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isUpdating ? '...نشر' : 'نشر'}{' '}
              {(isUploadingMedia || isUpdating) && <Loader size="sm" />}
            </Button>
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
}
