import { Skeleton } from '@/components/ui/skeleton';

export function TweetSkeleton() {
  return (
    <div className="w-full p-4 border-b border-gray-300 bg-white mt-10">
      <div className="flex items-start space-x-4">
        {/* Profile Image Skeleton */}
        <Skeleton className="h-10 w-10 rounded-full" />

        <div className="flex-1 space-y-3">
          {/* Header Skeleton */}
          <div className="flex items-center gap-2">
            <Skeleton className="h-4 w-24" /> {/* Name */}
            <Skeleton className="h-3 w-16" /> {/* Time */}
          </div>

          {/* Tweet Text Skeleton */}
          <Skeleton className="h-4 w-[90%]" />
          <Skeleton className="h-4 w-[80%]" />

          {/* Media Skeleton */}
          <Skeleton className="h-40 w-full rounded-lg" />

          {/* Engagement Icons Skeleton */}
          <div className="flex justify-start items-center gap-6 mt-3">
            <Skeleton className="h-4 w-8" /> {/* Like */}
            <Skeleton className="h-4 w-8" /> {/* Retweet */}
            <Skeleton className="h-4 w-8" /> {/* Comment */}
            <Skeleton className="h-4 w-8" /> {/* Download */}
          </div>
        </div>
      </div>
    </div>
  );
}
