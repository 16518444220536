import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import {
  useCreatePostMutation,
  useResharePostMutation,
} from '@/RTK/services/postsApi';
import {
  useDeleteFileMutation,
  useGetPresignedUrlMutation,
} from '@/RTK/services/s3Api';
// Import the RTK mutation
import { useGetUserQuery } from '@/RTK/services/userApi';
import { DialogTitle } from '@radix-ui/react-dialog';
import {
  Bold,
  Image as ImageIcon,
  Link,
  Video as VideoIcon,
  X,
} from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Textarea } from '@/components/ui/textarea';

import Loader from '../../../../../utils/Loader';
import RepostedTweet from './RepostedTweet';
import { UploadMediaCarousel } from './uploadMediaCarousel';

export function RePostQuoteDialog({ dialogOpen, setDialogOpen, tweet }) {
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]); // Store uploaded image URLs
  const [video, setVideo] = useState(null); // Store uploaded video URL
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);

  const textareaRef = useRef(null);

  // RTK Mutations
  const [createPost, { isLoading: isPosting }] = useCreatePostMutation();
  const [getPresignedUrl] = useGetPresignedUrlMutation();
  const [deleteFile] = useDeleteFileMutation();
  const { data: user, isLoading: userLoading } = useGetUserQuery(undefined);
  const [resharePost, { isLoading: reSharingPost, isError, isSuccess }] =
    useResharePostMutation();

  // Function to get a pre-signed URL and upload a file to S3
  const uploadFileToS3 = async (file) => {
    try {
      // Step 1: Get a pre-signed URL
      const { url, fileUrl } = await getPresignedUrl(file.type).unwrap();

      // Step 2: Upload the file to S3
      await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });

      // Step 3: Return the uploaded file URL
      return fileUrl;
    } catch (error) {
      console.error('Error uploading file to S3:', error);
      return null;
    }
  };

  // Function to handle image selection and upload
  const handleImageUpload = async (event) => {
    const files = event.target.files;
    if (files.length) {
      setIsUploadingMedia(true);
      const uploadedUrls = await Promise.all(
        Array.from(files).map((file) => uploadFileToS3(file))
      );
      setIsUploadingMedia(false);
      setImages((prevImages) => [
        ...uploadedUrls.filter(Boolean),
        ...prevImages,
      ]);
    }
    event.target.value = null;
  };

  // function to remover video from s3
  const removeVideoFromS3 = async (videoUrl) => {
    try {
      const key = videoUrl.split('.com/')[1]; // Extract S3 object key
      deleteFile(key).unwrap();
    } catch (error) {
      console.error('Error deleting video file:', error);
    } finally {
      setVideo(null);
    }
  };

  // Function to handle video selection and upload
  const handleVideoUpload = async (event) => {
    if (video) {
      removeVideoFromS3(video);
    }

    const file = event.target.files[0];
    if (file) {
      setIsUploadingMedia(true);
      const uploadedUrl = await uploadFileToS3(file);
      setVideo(uploadedUrl);
    }
    event.target.value = null;
    setIsUploadingMedia(false);
  };

  const removeImageFromS3 = async (imageUrl) => {
    try {
      const key = imageUrl.split('.com/')[1]; // Extract S3 object key
      deleteFile(key).unwrap();
      setImages((prevImages) => prevImages.filter((img) => img !== imageUrl));
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  // Function to remove an image from the list
  const removeImage = async (imageUrl) => {
    // Remove image from S3
    removeImageFromS3(imageUrl);

    // Remove the image from the list
    setImages((prevImages) => prevImages.filter((img) => img !== imageUrl));
  };

  useEffect(
    function closeDialogOnPostCreation() {
      if (!isPosting) {
        setDialogOpen(false);
      }
    },
    [isPosting]
  );

  // Function to create a post
  const handleCreatePost = async () => {
    try {
      const response = await resharePost({
        postId: tweet._id,
        postData: {
          content: {
            text: content,
            images,
            video,
          },
        },
      }).unwrap();

      setContent('');
      setImages([]);
      setVideo(null);
      textareaRef.current.focus();
    } catch (error) {
      console.error('Error creating post:', error);
    } finally {
      setDialogOpen(false);
    }
  };

  // Function to close the dialog it deletes any video or images that are uploaded to aws on closing the dialog
  function handleCloseDialog() {
    setContent('');

    if (video) removeVideoFromS3(video);
    setVideo(null);
    images.forEach((image) => removeImageFromS3(image));
    setImages([]);
    setDialogOpen(false);
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild className="hidden">
        <button className="flex items-center justify-between h-[62px] w-full px-4 py-2 rounded-lg text-gray-500 text-right bg-[#F7F5F5]">
          <div className="flex items-center gap-2 rounded-full">
            {user?.user?.profileImage ? (
              <Image
                width={32}
                height={32}
                src={user.user.profileImage}
                alt="User"
                className="w-8 h-8 rounded-full"
              />
            ) : (
              <div className="size-8 rounded-full bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center">
                <span className="text-sm font-medium text-purple-600">
                  {user?.user?.name?.charAt(0) || 'A'}
                </span>
              </div>
            )}
            <span className="text-[16.681px] font-medium text-[#71717A]">
              ما في بالك؟
            </span>
          </div>
        </button>
      </DialogTrigger>
      <DialogContent
        hideCloseButton={true}
        className="sm:max-w-[600px] p-6 rounded-2xl w-[95vw] h-[90vh] flex flex-col"
        dir="rtl"
        onInteractOutside={(e) => e.preventDefault()}
      >
        <DialogTitle className="hidden">Upload Media Dialog</DialogTitle>
        <DialogHeader className="flex flex-col p-2 h-[10%]">
          <div className="flex items-center gap-3">
            {user?.user?.profileImage ? (
              <Image
                width={53}
                height={53}
                src={user.user.profileImage}
                alt="User"
                className="size-[53px] rounded-full"
              />
            ) : (
              <div className="size-[53px] rounded-full bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center">
                <span className="text-sm font-medium text-purple-600">
                  {user?.user?.name?.charAt(0) || 'A'}
                </span>
              </div>
            )}
            <div className="flex flex-col gap-2 justify-end">
              <h3 className="text-[18px] font-semibold text-right text-[#65656B]">
                {user?.user?.name || 'username'}
              </h3>
            </div>
          </div>
        </DialogHeader>
        <ScrollArea
          className={`${tweet?.content?.images.length || tweet?.content?.video ? 'grow' : 'grow'}`}
        >
          <textarea
            ref={textareaRef}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            dir="rtl"
            placeholder="ماذا يخطر ببالك؟"
            className="w-full h-[70px] p-4 text-right text-gray-900 !border-none focus:ring-0 ring-0 !outline-non focus:outline-none resize-none"
          />

          {(images.length || video) && (
            <UploadMediaCarousel
              image={images}
              removeImage={removeImage}
              video={video}
              removeVideo={removeVideoFromS3}
            />
          )}
          <div dir="rtl">
            <RepostedTweet tweet={tweet} />
          </div>
        </ScrollArea>

        <div className="flex justify-between items-center gap-3 mt-4 flex-wrap h-[20%]">
          <div className="flex items-center gap-4 mt-2 text-gray-500">
            {/* <Bold className="w-5 h-5 cursor-pointer hover:text-gray-700" /> */}
            {/* <Link className="w-5 h-5 cursor-pointer hover:text-gray-700" /> */}
            <label className="cursor-pointer">
              <ImageIcon className="w-5 h-5 hover:text-gray-700" />
              <input
                type="file"
                accept="image/*"
                multiple
                className="hidden"
                disabled={isUploadingMedia}
                onChange={handleImageUpload}
              />
            </label>
            <label className="cursor-pointer">
              <VideoIcon className="w-5 h-5 hover:text-gray-700" />
              <input
                disabled={isUploadingMedia}
                type="file"
                accept="video/*"
                className="hidden"
                onChange={handleVideoUpload}
              />
            </label>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={handleCloseDialog}
              className="bg-gray-300 text-gray-700 px-6 py-2 rounded-lg w-[75px] h-[31px]"
            >
              إلغاء
            </Button>
            <Button
              onClick={handleCreatePost}
              disabled={
                isUploadingMedia ||
                (images.length === 0 && !video && content.trim() === '')
              }
              className={`bg-purple-600 text-white px-6 py-2 rounded-lg w-[75px] h-[31px] ${isUploadingMedia ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {reSharingPost ? '...نشر' : 'نشر'}{' '}
              {(isUploadingMedia || reSharingPost) && <Loader size="sm" />}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
