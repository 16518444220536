import domtoimage from 'dom-to-image';
import toast from 'react-hot-toast';

export function getTimeAgo(postDate) {
  const postDateTime = new Date(postDate);
  const today = new Date();

  // Check if post date is in the future
  if (postDateTime > today) {
    return 'في المستقبل';
  }

  // Calculate time difference in milliseconds
  const timeDiffInMs = today - postDateTime;

  // Convert milliseconds to seconds, minutes, hours, and days
  const secondsAgo = Math.floor(timeDiffInMs / 1000);
  const minutesAgo = Math.floor(secondsAgo / 60);
  const hoursAgo = Math.floor(minutesAgo / 60);
  const daysAgo = Math.floor(hoursAgo / 24);

  // Determine the appropriate time unit
  if (daysAgo > 0) {
    if (daysAgo === 1) {
      return '  قبل يوم واحد';
    } else {
      return ` منذ ${daysAgo} أيام  `;
    }
  } else if (hoursAgo > 0) {
    return `قبل ${hoursAgo} ساعة  `;
  } else if (minutesAgo > 0) {
    return `${minutesAgo} دقيقة قبل`;
  } else if (secondsAgo > 0) {
    return `${secondsAgo} ثانية قبل`;
  } else {
    return 'الآن';
  }
}

export const handleDownload = async (cardRef, setDownloadPhotoId) => {
  if (!cardRef.current) {
    toast.error('لم يتم العثور على البطاقة');
    return;
  }

  // Show loading toast
  const loadingToast = toast.loading('جاري تحميل البطاقة...');
  // await here for 10 seconds
  await new Promise((resolve) => setTimeout(resolve, 2000));
  try {
    const scale = 1;
    const node = cardRef.current;

    const style = {
      width: node.offsetWidth + 'px',
      height: node.offsetHeight + 'px',
    };

    const param = {
      height: node.offsetHeight,
      width: node.offsetWidth,
      quality: 1,
      style,
    };

    // Wait for images to load
    const images = node.getElementsByTagName('img');
    await Promise.all(
      Array.from(images).map((img) => {
        if (img.complete) {
          return Promise.resolve();
        }
        return new Promise((resolve, reject) => {
          img.addEventListener('load', resolve);
          img.addEventListener('error', reject);
        });
      })
    );

    // Configure dom-to-image options
    const options = {
      ...param,
      filter: (node) => {
        // Keep images that have already loaded
        if (node.tagName === 'IMG') {
          return node.complete;
        }
        return true;
      },
      cacheBust: true,
    };

    // Generate image
    const dataUrl = await domtoimage.toPng(node, options);

    // Generate image
    // const dataUrl = await domtoimage.toPng(node, param);

    // Download image
    const link = document.createElement('a');
    link.download = `${'preview'}-card.png`;
    link.href = dataUrl;
    link.click();

    toast.success('تم تحميل البطاقة بنجاح');
    toast.dismiss(loadingToast);
    setDownloadPhotoId(null);
  } catch (err) {
    toast.dismiss(loadingToast);
    console.error('Error details:', err);
    toast.error('حدث خطأ أثناء تحميل البطاقة. يرجى المحاولة مرة أخرى');
  }
};
