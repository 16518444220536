import Image from 'next/image';
import { useRouter } from 'next/navigation';

import { Skeleton } from '@/components/ui/skeleton';

const ArticlePreview = ({
  article,
  openSimplePreview,
  isLoadingArticle = false,
}) => {
  const router = useRouter();

  if (isLoadingArticle) {
    return <ArticleSkeleton />;
  }

  return openSimplePreview() ? (
    <div
      className="flex flex-col backdrop-blur-lg  w-full bg-[#eeeeee] border rounded-[12px] p-4 cursor-pointer my-2 hover:shadow-md"
      onClick={(e) => {
        e.stopPropagation();
        router.push(`/view-article/${article._id}`, '_blank');
      }}
    >
      <div className="flex gap-2 items-center text-[#ffffff] mb-2">
        {article?.author?.profileImage ? (
          <Image
            src={article?.author?.profileImage}
            alt={article?.author?.name || 'username'}
            width={20}
            height={20}
            className="w-5 h-5 rounded-full cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              router.push(`/@${article?.author?.username}`);
            }}
          />
        ) : (
          <div
            onClick={(e) => {
              e.stopPropagation();
              router.push(`/@${article?.author?.username}`);
            }}
            className="size-[20px] rounded-full bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center cursor-pointer"
          >
            <span className="text-sm font-medium text-purple-600">
              {article?.author?.name?.charAt(0) || 'A'}
            </span>
          </div>
        )}
        <p className="text-[12px] text-black">{article?.author?.name}</p>
      </div>
      <div className="flex-1">
        <h3 className="font-semibold text-lg">{article.title}</h3>
        {/* {article.subTitle && (
          <p className="text-gray-600 mt-2">{article.subTitle}</p>
        )} */}
      </div>
    </div>
  ) : (
    <div
      className="relative mt-4 border rounded-lg h-[250px] flex flex-col justify-end"
      dir="rtl"
      onClick={(e) => {
        e.stopPropagation();
        router.push(`/view-article/${article._id}`, '_blank');
      }}
    >
      <div className="flex flex-col ">
        {article.coverImage && (
          <img
            src={article.coverImage}
            alt="Article cover"
            className="absolute inset-0 w-full h-full object-cover rounded-lg"
          />
        )}
        <div className="relative z-10 flex-1 backdrop-blur-lg cursor-pointer rounded-b-lg px-4 min-h-[60px] pt-1">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent rounded-b-lg to-black/60"></div>
          <div className="flex gap-2 items-center text-[#ffffff] mb-2 relative z-20">
            {article?.author?.profileImage ? (
              <Image
                unoptimized={true}
                src={article?.author?.profileImage}
                alt={article?.author?.name || 'username'}
                width={20}
                height={20}
                className="w-5 h-5 rounded-full cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  router.push(`/@${article?.author?.username}`);
                }}
              />
            ) : (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  router.push(`/@${article?.author?.username}`);
                }}
                className="size-[20px] rounded-full bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center cursor-pointer"
              >
                <span className="text-sm font-medium text-purple-600">
                  {article?.author?.name?.charAt(0) || 'A'}
                </span>
              </div>
            )}
            <p className="text-[12px]">{article?.author?.name}</p>
          </div>
          <div className="flex flex-col  mb-2 ">
            <h3 className=" text-lg text-white relative z-20">
              {article.title}
            </h3>
            {/* {article.subTitle && (
              <p className=" mt-2 text-white">{article.subTitle}</p>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

function ArticleSkeleton() {
  return (
    <div className="relative mt-4 border rounded-lg h-[250px] flex flex-col justify-end">
      <Skeleton className="absolute inset-0 w-full h-full object-cover rounded-lg" />
      <div className="flex-1 backdrop-blur-lg rounded-b-lg px-4 min-h-[60px] pt-1">
        <div className="flex gap-2 items-center text-[#ffffff] mb-2">
          <Skeleton className="w-5 h-5 rounded-full" />
          <Skeleton className="h-4 w-24" />
        </div>
        <div className="flex flex-col mb-2">
          <Skeleton className="h-6 w-[80%]" />
          <Skeleton className="h-4 w-[60%] mt-2" />
        </div>
      </div>
    </div>
  );
}

export default ArticlePreview;
