import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useGetArticleByIdQuery } from '@/RTK/services/articleApi';
import {
  useLikePostMutation,
  useUnlikePostMutation,
} from '@/RTK/services/postsApi';
import { useGetUserQuery } from '@/RTK/services/userApi';
import { getTime } from 'date-fns';
import { AiOutlineRetweet } from 'react-icons/ai';
import { BiComment } from 'react-icons/bi';
import { FaComment, FaDownload, FaHeart, FaRetweet } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { IoIosHeart, IoIosHeartEmpty } from 'react-icons/io';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

import { getTimeAgo } from '../utils/helper';
import ArticlePreview from './ArticlePreview';
import CommentModal from './CommentModal';
import { EditPostDialog } from './EditPostDialog';
import { PostMediaCarousel } from './postMediaCarousel';
import { RepostOptionsDropdownMenu } from './RepostOptionsDropdown';
import TweetActionDropDownList from './TweetActionDropDownList';

export default function RepostedTweetCard({ originalTweet }) {
  const router = useRouter();
  const [showFullText, setShowFullText] = useState(false);

  let tweet = originalTweet?.post;

  const {
    data: articleData,
    isLoading: isLoadingArticle,
    error: articleError,
  } = useGetArticleByIdQuery(tweet?.content?.articleId, {
    skip: !tweet?.content?.articleId,
  });
  function shouldCarouselOpen() {
    if (
      tweet?.content?.images.length > 1 ||
      (tweet?.content?.images.length === 1 && tweet?.content?.video)
    )
      return true;
    return false;
  }

  function renderTextContent() {
    if (!showFullText)
      return tweet?.content?.text
        .split('\n')
        .slice(0, 3)
        .join('\n')
        .replace(/\n/g, '\n &nbsp;');

    return tweet?.content?.text.replace(/\n/g, '\n &nbsp;');
  }

  return (
    <div
      className="w-[100%]  p-4 border border-gray-300 bg-white mt-4 rounded-[12px] hover:shadow-lg cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        router.push(`/feed/post/${tweet._id}`);
      }}
    >
      <div className="flex items-start space-x-4">
        {/* Tweet Content */}
        <div className="flex-1">
          {/* Header */}
          <div className="flex items-center gap-4 justify-between">
            {/* right section */}
            <div className="flex items-center gap-3 ">
              {/* Profile Image */}
              {tweet?.author?.profileImage ? (
                <Image
                  src={tweet?.author?.profileImage}
                  alt={tweet?.author?.name || 'username'}
                  width={40}
                  height={40}
                  className="w-10 h-10 rounded-full cursor-pointer"
                  onClick={() => router.push(`/@${tweet?.author?.username}`)}
                />
              ) : (
                <div
                  onClick={() => router.push(`/@${tweet?.author?.username}`)}
                  className="size-[40px] rounded-full bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center cursor-pointer"
                >
                  <span className="text-sm font-medium text-purple-600">
                    {tweet?.author?.name?.charAt(0) || 'A'}
                  </span>
                </div>
              )}

              {/* details */}
              <div className="flex gap-2 items-center">
                <span
                  onClick={() => router.push(`/@${tweet?.author?.username}`)}
                  className="text-[14.457px] font-medium text-[#222] cursor-pointer"
                >
                  {tweet?.author?.name || 'username'}
                </span>
                <span className="text-[#6E7881] text-[11.121px] font-normal">
                  {getTimeAgo(tweet?.createdAt)}
                </span>
              </div>
            </div>
          </div>

          <div className=" sm:mr-9 mt-2 w-[100%] sm:w-[90%] mx-auto">
            {/* Tweet Text */}
            {/* <p className="text-[#7E7A7A] text-[13.345px] font-medium tracking-[-0.4px] text-justify  mr-4">
              {tweet?.content?.text || 'tweet content will show here'}
            </p> */}

            <div className="text-[#7E7A7A] text-[15px] font-medium tracking-[-0.4px] mr-3  sm:mr-4 text-wrap break-normal break-words text-right">
              <ReactMarkdown
                onClick={(e) => e.stopPropagation()}
                remarkPlugins={[remarkGfm, remarkBreaks]}
                components={{
                  a: ({ node, ...props }) => (
                    <a
                      {...props}
                      className="text-purple-600 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                }}
              >
                {renderTextContent()}
              </ReactMarkdown>
              {tweet?.content?.text.split('\n').length > 3 && !showFullText && (
                <span
                  className="text-purple-600 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFullText(true);
                  }}
                >
                  عرض المزيد...
                </span>
              )}
            </div>

            {/* Tweet Image */}
            {tweet?.content?.images.length == 1 && !tweet?.content?.video && (
              <Image
                src={tweet?.content?.images[0]}
                alt={tweet?.author?.name || 'username'}
                width={0}
                height={0}
                sizes="100%"
                className="w-full h-full object-cover mt-2 rounded-[10px]"
              />
            )}

            <div className="w-[100%]">
              {shouldCarouselOpen() && (
                <PostMediaCarousel
                  image={tweet?.content?.images}
                  video={tweet?.content?.video}
                />
              )}
            </div>

            {tweet?.content?.images.length == 0 && tweet?.content?.video && (
              <div className="relative mt-4 bg-gray-100 rounded-lg">
                <video
                  src={tweet?.content?.video}
                  controls
                  className="w-full h-full mt-2 rounded-lg "
                />
              </div>
            )}
            {!tweet?.isReshare && articleData && (
              <ArticlePreview
                article={articleData?.article}
                openSimplePreview={() =>
                  tweet?.content?.images.length != 0 || tweet?.content?.video
                }
              />
            )}
          </div>
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-t from-black/[0.03] to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none" />
    </div>
  );
}
