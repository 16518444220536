import * as React from 'react';
import Image from 'next/image';
import { X } from 'lucide-react';
import { MdBookmarkBorder } from 'react-icons/md';
import { PhotoProvider, PhotoView } from 'react-photo-view';

import { truncateString } from '@/lib/utils';
import { Card, CardContent } from '@/components/ui/card';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';

import { images } from '../../../../../../next.config';
import ArticleActionDropDownList from './ArticleActionDropDownList';

// import { Progress } from '@/components/ui/progress';

export function PostMediaCarousel({ image, video }) {
  return (
    <div>
      <div className="flex justify-between items-center mb-4"></div>
      <Carousel
        opts={{
          direction: 'rtl',
        }}
        className="w-full  lg:max-w-full bg-white"
      >
        <CarouselContent
          className=" bg-white gap-2 ml-4"
          dir="rtl"
          onClick={(e) => e.stopPropagation()}
        >
          {image?.map((image, index) => (
            <CarouselItem
              key={index}
              className=" basis-[164px] sm:basis-[184px]  bg-white pl-0"
            >
              <PhotoProvider>
                <div className="relative mt-4 ">
                  <PhotoView src={image}>
                    <Image
                      src={image}
                      alt="Uploaded"
                      width={0}
                      height={0}
                      unoptimized={true}
                      sizes="100%"
                      className="w-full rounded-lg object-cover h-[200px] "
                    />
                  </PhotoView>
                </div>
              </PhotoProvider>
            </CarouselItem>
          ))}
          {video && (
            <CarouselItem
              key={images.length}
              className=" basis-[184px] lg:basis-[184px]  "
            >
              <div className="relative mt-4 bg-gray-100 rounded-lg">
                <video
                  src={video}
                  controls
                  className="w-full h-[200px] rounded-lg "
                />
              </div>
            </CarouselItem>
          )}
        </CarouselContent>
        <CarouselPrevious variant="ghost" className="hidden " />
        <CarouselNext variant="ghost" className="hidden " />
      </Carousel>
    </div>
  );
}
