import { useState } from 'react';
import { useDeletePostMutation } from '@/RTK/services/postsApi';
import { useDeleteFileMutation } from '@/RTK/services/s3Api';
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlaySquare,
} from 'react-icons/ai';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import Loader from '../../../../../utils/Loader';
import { EditPostDialog } from './EditPostDialog';

export default function TweetActionDropDownList({
  id,
  tweet,
  setIsEditPostDialogOpen,
  isReposted,
}) {
  const [deletePost, { isLoading: deleteLoading }] = useDeletePostMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [deleteFile] = useDeleteFileMutation();
  const { token } = useSelector((state) => state.auth);

  const removeMediaFromS3 = async (media) => {
    try {
      const key = media.split('.com/')[1]; // Extract S3 object key
      deleteFile(key).unwrap();
    } catch (error) {
      console.error('Error deleting video file:', error);
    }
  };

  function deleteHandler() {
    if (!token) {
      toast.error('يجب عليك تسجيل الدخول اولاً');
      return;
    }
    tweet?.content?.images?.forEach((image) => removeMediaFromS3(image));
    tweet?.content?.video && removeMediaFromS3(tweet.content.video);
    deletePost(id);
  }

  return (
    <>
      <DropdownMenu dir="rtl" open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger asChild>
          <HiOutlineDotsHorizontal
            size={20}
            color="#C7C7C7"
            className="cursor-pointer"
            onClick={(event) => {
              // event.stopPropagation();
            }}
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-48 rounded-md bg-[#FAFAFA] p-2 shadow-lg border border-[#E4E4E7]"
          align="end"
        >
          <DropdownMenuItem
            className={`flex items-center gap-2 rounded-md p-2 text-[#2E2E2E] hover:bg-gray-100 ${isReposted ? 'hidden' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              onClick={(event) => {
                setIsEditPostDialogOpen(true);
                setIsOpen(false);
                event.stopPropagation(); // Prevent DropdownMenu from closing when Dialog is triggered
              }}
              className={`w-full `}
            >
              <button className="flex w-full shrink-0 justify-between rounded-[6px] text-[#505050]">
                تحرير المشاركة <AiOutlineEdit size={16} color="#636363" />
              </button>
            </div>
          </DropdownMenuItem>
          {/* <DropdownMenuItem
            className="flex items-center gap-2 rounded-md p-2 text-[#484851] hover:bg-gray-100"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              onClick={(event) => {
                event.stopPropagation(); // Prevent DropdownMenu from closing when Dialog is triggered
              }}
              className="w-full"
            >
              <button className="flex w-full shrink-0 justify-between rounded-[6px] text-[#484851]">
                نص طويل <IoDocumentTextOutline size={16} color="#636363" />
              </button>
            </div>
          </DropdownMenuItem> */}
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="flex items-center gap-2 rounded-md p-2 text-[#2E2E2E] hover:bg-gray-100"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {/* delete post button */}
            <div
              onClick={(event) => {
                deleteHandler();
                event.stopPropagation(); // Prevent DropdownMenu from closing when Dialog is triggered
              }}
              className="w-full"
            >
              <button className="flex w-full shrink-0 justify-between items-center rounded-[6px] text-red-300">
                {deleteLoading && (
                  <Loader size="sm" className={'border-red-300'} />
                )}{' '}
                حذف <AiOutlineDelete size={16} />
              </button>
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
