'use client';

import React, { useState } from 'react';
import {
  useGetPostByIdQuery,
  useResharePostMutation,
} from '@/RTK/services/postsApi';
import { RefreshCcw } from 'lucide-react';
import toast from 'react-hot-toast';
import { AiOutlineRetweet } from 'react-icons/ai';
import { LuMessageSquareMore } from 'react-icons/lu';
import { useSelector } from 'react-redux';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import Loader from '../../../../../utils/Loader';
import { RePostQuoteDialog } from './RepostQuoteDialog';

export function RepostOptionsDropdownMenu({ repostCount, tweet }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [resharePost, { isLoading: reSharingPost, isError, isSuccess }] =
    useResharePostMutation();
  const { token } = useSelector((state) => state.auth);

  async function handleRepost() {
    if (!token) {
      toast.error('يجب عليك تسجيل الدخول اولاً');
      return;
    }
    try {
      let res = await resharePost({ postId: tweet._id, postData: {} }).unwrap();
    } catch (error) {
      console.log('Error while reposting', error);
    }
  }
  return (
    <>
      <DropdownMenu
        open={dropDownOpen}
        onOpenChange={setDropDownOpen}
        dir="rtl"
      >
        <DropdownMenuTrigger asChild className="outline-none">
          <button className="flex items-center p-1 gap-1 min-w-[40px] w-auto h-[40px] rounded-[15px] hover:bg-gray-200 justify-center">
            <RefreshCcw size={16} />{' '}
            <span className="text-[#7E7A7A] text-[16px] font-medium">
              {repostCount > 0 ? repostCount : ''}
            </span>
          </button>
          {/* tweet?.reshares?.length  */}
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[100px]">
          <DropdownMenuItem
            className="flex justify-between cursor-pointer"
            onClick={handleRepost}
          >
            {reSharingPost && <Loader size="sm" />} إعادة نشر{' '}
            <AiOutlineRetweet size={16} />
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="flex justify-between cursor-pointer"
            onClick={() => {
              if (!token) {
                toast.error('يجب عليك تسجيل الدخول اولاً');
                return;
              }
              setDropDownOpen(false);
              setDialogOpen(true);
            }}
          >
            اقتباس <LuMessageSquareMore />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <RePostQuoteDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        tweet={tweet}
      />
    </>
  );
}
