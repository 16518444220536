import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import {
  useLikePostMutation,
  useUnlikePostMutation,
} from '@/RTK/services/postsApi';
import { useGetUserQuery } from '@/RTK/services/userApi';
import { getTime } from 'date-fns';
import { AiOutlineRetweet } from 'react-icons/ai';
import { BiComment } from 'react-icons/bi';
import { FaComment, FaDownload, FaHeart, FaRetweet } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { IoIosHeart, IoIosHeartEmpty } from 'react-icons/io';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

import { getTimeAgo } from '../utils/helper';
import CommentModal from './CommentModal';
import { EditPostDialog } from './EditPostDialog';
import { PostMediaCarousel } from './postMediaCarousel';
import { RepostOptionsDropdownMenu } from './RepostOptionsDropdown';
import TweetActionDropDownList from './TweetActionDropDownList';

export default function RepostedTweet({ tweet }) {
  function shouldCarouselOpen() {
    if (
      tweet?.content?.images.length > 1 ||
      (tweet?.content?.images.length === 1 && tweet?.content?.video)
    )
      return true;
    return false;
  }

  return (
    <div className="w-full p-4 border-b border-gray-300 bg-white mt-4">
      <div className="flex items-start space-x-4">
        {/* Tweet Content */}
        <div className="flex-1">
          {/* Header */}
          <div className="flex items-center gap-4 justify-between">
            {/* right section */}
            <div className="flex items-center gap-3">
              {/* Profile Image */}
              {tweet?.author?.profileImage ? (
                <Image
                  src={tweet?.author?.profileImage}
                  alt={tweet?.author?.name || 'username'}
                  width={40}
                  height={40}
                  className="w-10 h-10 rounded-full"
                />
              ) : (
                <div className="size-[40px] rounded-full bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center">
                  <span className="text-sm font-medium text-purple-600">
                    {tweet?.author?.name?.charAt(0) || 'A'}
                  </span>
                </div>
              )}

              {/* details */}
              <div className="flex gap-2 items-center">
                <span className="text-[14.457px] font-medium text-[#222]">
                  {tweet?.author?.name || 'username'}
                </span>
                <span className="text-[#6E7881] text-[11.121px] font-normal">
                  {getTimeAgo(tweet?.createdAt)}
                </span>
              </div>
            </div>
          </div>

          <div className="mr-9 mt-2 w-[86%] mx-auto">
            {/* Tweet Text */}
            <div className="text-[#7E7A7A] text-[15px] font-medium tracking-[-0.4px] text-justify mr-4  break-words break-all">
              <ReactMarkdown
                onClick={(e) => e.stopPropagation()}
                remarkPlugins={[remarkGfm, remarkBreaks]}
                components={{
                  a: ({ node, ...props }) => (
                    <a
                      {...props}
                      className="text-purple-600 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                }}
              >
                {tweet?.content?.text.replace(/\n/g, '\n &nbsp;')}
              </ReactMarkdown>
            </div>
            {/* Tweet Image */}
            {tweet?.content?.images.length == 1 && !tweet?.content?.video && (
              <Image
                src={tweet?.content?.images[0]}
                alt={tweet?.author?.name || 'username'}
                width={0}
                height={0}
                sizes="100%"
                className="w-full h-full object-cover mt-2 rounded-[10px]"
              />
            )}

            <div className="w-[90%]">
              {shouldCarouselOpen() && (
                <PostMediaCarousel
                  image={tweet?.content?.images}
                  video={tweet?.content?.video}
                />
              )}
            </div>

            {tweet?.content?.images.length == 0 && tweet?.content?.video && (
              <div className="relative mt-4 bg-gray-100 rounded-lg">
                <video
                  src={tweet?.content?.video}
                  controls
                  className="w-full h-full mt-2 rounded-lg "
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-t from-black/[0.03] to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none" />
    </div>
  );
}
