'use client';

import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import {
  useAddPostCommentMutation,
  useGetPostCommentsQuery,
  useReplyToCommentMutation,
} from '@/RTK/services/postsApi';
import { DialogTitle } from '@radix-ui/react-dialog';
import {
  ArrowUp,
  CornerDownRight,
  Edit,
  MessageCircle,
  MoreVertical,
  Trash2,
} from 'lucide-react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { IoCloseSharp } from 'react-icons/io5';
import { useSelector } from 'react-redux';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import ProfileImage from '@/components/ui/profile-image';

import Loader from '../../../../../utils/Loader';

const dummyComments = [
  {
    id: '1',
    username: 'user1',
    name: 'User One',
    image: 'https://i.pravatar.cc/40',
    comment: 'This is a great post!',
    date: '2h ago',
    postedBy: 'user1',
    replies: [
      {
        id: '2',
        username: 'user2',
        name: 'User Two',
        image: 'https://i.pravatar.cc/40',
        comment: 'I totally agree!',
        date: '1h ago',
        postedBy: 'user2',
        replies: [],
      },
    ],
  },
  {
    id: '3',
    username: 'user3',
    name: 'User Three',
    image: 'https://i.pravatar.cc/40',
    comment: 'Nice perspective!',
    date: '30m ago',
    postedBy: 'user3',
    replies: [],
  },
];

const Comment = ({ comment, tweetId }) => {
  const [showReplyForm, setShowReplyForm] = useState(false);
  const { register, handleSubmit, reset } = useForm();

  const [showReplies, setShowReplies] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [isReplying, setIsReplying] = useState(false);

  const [replyToComment, { isLoading: isReplyingPending }] =
    useReplyToCommentMutation();

  async function onReplySubmit() {
    try {
      await replyToComment({
        postId: tweetId,
        commentId: comment._id,
        reply: replyText,
      }).unwrap();
    } catch (error) {
      console.log(error);
    } finally {
      setIsReplying(false);
      setReplyText('');
    }
  }

  return (
    <div className="border-b pb-3 pt-2">
      <div className="flex items-start gap-3">
        <ProfileImage
          userName={comment?.user?.name}
          src={comment?.user?.profileImage}
          alt={comment?.user?.username}
          size="sm"
        />
        <div className="flex-1 max-w-[90%]">
          <div className="flex items-center justify-between">
            <span className="font-medium text-sm">{comment?.user?.name}</span>
            <span className="text-xs text-gray-500">
              {getTimeAgo(comment?.createdAt)}
            </span>
          </div>
          <p className="text-sm text-gray-700 break-words ">
            {comment?.content}
          </p>

          {/* Reply Button */}
          <div className="flex items-center gap-2 mt-1">
            <button
              className={`text-xs text-blue-600 ${comment.replies.length === 0 && 'hidden'}`}
              onClick={() => setShowReplies(!showReplies)}
            >
              {showReplies
                ? 'Hide Replies'
                : `View Replies (${comment.replies.length})`}
            </button>
            <button
              className="text-xs text-green-600 flex items-center gap-1"
              onClick={() => setIsReplying(!isReplying)}
            >
              <MessageCircle size={12} /> Reply
            </button>
          </div>

          {/* Reply Input */}
          {isReplying && (
            <div className="mt-2 flex gap-2">
              <input
                type="text"
                className="border px-2 py-1 text-sm w-[150px] sm:width-full rounded-md"
                placeholder="Write a reply..."
                value={replyText}
                onChange={(e) => setReplyText(e.target.value)}
              />
              <Button size="sm" onClick={onReplySubmit} className="text-xs">
                Reply {isReplyingPending && <Loader size="sm" />}
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* Replies Section */}
      {showReplies && comment.replies.length > 0 && (
        <div className="mt-3  p-2 rounded-lg">
          {comment.replies.map((reply) => (
            <Comment key={reply._id} comment={reply} tweetId={tweetId} />
          ))}
        </div>
      )}
    </div>
  );
};

const CommentModal = ({ isOpen, onClose, tweetId }) => {
  const currentUserId = useSelector((state) => state.auth?.user?._id);
  const [comments, setComments] = useState(dummyComments);
  const [commentText, setCommentText] = useState('');

  const [addComment, { isLoading: commentAdding }] =
    useAddPostCommentMutation();
  const {
    data: postComments,
    isLoading: commentLoading,
    isError,
  } = useGetPostCommentsQuery(tweetId, {
    skip: !isOpen,
  });

  const handleAddComment = async (e) => {
    e.preventDefault();
    try {
      await addComment({ postId: tweetId, comment: commentText }).unwrap();

      setCommentText('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogTitle className="hidden">Comments</DialogTitle>
      <DialogContent
        className="p-0 h-[90vh] w-[95vw] sm:w-[28rem] flex flex-col"
        dir="rtl"
        hideclosebutton={true}
        onClick={(e) => e.stopPropagation()}
      >
        {commentLoading ? (
          <div className="h-full flex items-center justify-center">
            <Loader />
          </div>
        ) : isError ? (
          <div className="h-full flex items-center justify-center">
            <h1>Something went wrong</h1>
          </div>
        ) : (
          <>
            <div className="px-4 py-3 border-b bg-white flex justify-between items-center">
              <h2 className="text-lg font-semibold flex items-center gap-2 ">
                <MessageCircle className="w-5 h-5" /> تعليقات
              </h2>
              <IoCloseSharp
                onClick={(e) => {
                  e.stopPropagation();
                  onClose(false);
                }}
                size={20}
                className="cursor-pointer hover:opacity-70"
              />
            </div>
            <div className="flex-1 overflow-y-auto p-4 space-y-4">
              {postComments?.comments?.map((comment) => (
                <Comment
                  tweetId={tweetId}
                  key={comment._id}
                  comment={comment}
                />
              ))}
            </div>
            <div className="p-4 border-t bg-white">
              <form
                onSubmit={handleAddComment}
                className="flex gap-2 items-center"
              >
                <input
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  className="w-[80%] px-4 py-2 text-sm border rounded-full flex-1"
                  placeholder="اكتب تعليقا..."
                />
                <Button
                  type="submit"
                  className="shrink-0 bg-[#8641BD]"
                  size="sm"
                >
                  بريد {commentAdding && <Loader />}
                </Button>
              </form>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CommentModal;

function getTimeAgo(postDate) {
  const postDateTime = new Date(postDate);
  const today = new Date();

  // Normalize both dates to remove time (only compare YYYY-MM-DD)
  const postDateOnly = new Date(
    postDateTime.getFullYear(),
    postDateTime.getMonth(),
    postDateTime.getDate()
  );
  const todayOnly = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const timeDifference = todayOnly - postDateOnly;
  const daysAgo = timeDifference / (1000 * 60 * 60 * 24); // Convert milliseconds to days

  if (daysAgo === 0) {
    return 'Today';
  } else {
    return `${daysAgo} أيام قبل`;
  }
}
